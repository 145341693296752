<template>
  <div class="formSection">
    <fieldset class="rounded p-3 card">
      <h5 class="text-center fw-bolder">{{ sectionName }}</h5>
      <div class="mb-3">
        <label for="name" class="form-label">* Nombres y Apellidos</label>
        <input
          type="text"
          class="form-control"
          name="name"
          :minlength="nameMin"
          :maxlength="nameMax"
          v-model="name"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese un nombre para el empleado
        </div>
      </div>
      <div class="mb-3">
        <label for="IDType" class="form-label">* Tipo de Documento</label>
        <select
          name="IDType"
          class="form-select"
          aria-label="Default select example"
          required
        >
          <option value="CC">Cédula de Ciudadanía</option>
          <option value="CE">Cédula de Extranjería</option>
          <option value="PEP">Permiso Especial de Permanencia</option>
        </select>
      </div>
      <div class="mb-3">
        <label for="ID" class="form-label">* Número de Documento</label>
        <input
          type="number"
          class="form-control"
          name="ID"
          :min="IDMin"
          :max="IDMax"
          v-model="ID"
          @keyup="checkCurrentEmployee()"
          required
        />
        <div class="invalid-feedback">
          Por favor ingrese un número de documento válido
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-primary shadow-primary"
          @click.prevent="submit()"
        >
          {{ submitName }}
        </button>
      </div>
    </fieldset>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

const store = new Storage();

export default {
  props: ["sectionName", "defaultData", "submitName"],
  data() {
    return {
      initDefault: true,
      mounted: false,
      name: "",
      ID: "",
      nameMin: 0,
      nameMax: 1,
      IDMin: 0,
      IDMax: 1
    };
  },
  mounted() {
    store.getData("schema").then(schemaData => {
      schemaData.forEach(schema => {
        if (schema.store === "employee") {
          this.nameMin = schema.data.name.min;
          this.nameMax = schema.data.name.max;
          this.IDMin = schema.data.ID.min;
          this.IDMax = schema.data.ID.max;
        }
      });
      this.mounted = true;
    });
  },
  methods: {
    submit() {
      this.$emit("submitForm", true);
    },
    next(event) {
      this.$emit("moveForm", event);
    },
    checkCurrentEmployee() {
      store.getData("employee").then(employeeData => {
        employeeData.forEach(employee => {
          if (employee.ID == this.ID) {
            Swal.fire({
              icon: "error",
              title: "No es posible crear el empleado",
              text:
                "Ya existe un empleado registrado con el número de documento: " +
                employee.ID
            }).then(() => {
              this.ID = "";
            });
          }
        });
      });
    }
  },
  watch: {
    defaultData: function(val) {
      if (this.initDefault) {
        const defaultInterval = setInterval(() => {
          if (this.mounted) {
            clearInterval(defaultInterval);
            if (val.name !== undefined && val.name !== null) {
              this.name = val.name;
            }
          }
        }, 10);
        this.initDefault = false;
      }
    },
    submitValidate: function(val) {
      if (this.errorMsg !== "") {
        this.$emit("moveForm", this.sectionPosition);
      }
    }
  },
  name: "Employee Profile Form 1"
};
</script>

<style lang="css" scoped>
.formSection {
  min-width: 100vw;
}
.formSection fieldset {
  width: 350px;
  background-color: var(--secondary-bg);
  margin: auto;
}
</style>
